// Magical prefixer
@mixin prefix($property, $value...) {  // Chews experimental css properties ...
  $prefixes:      ('-webkit-', '-moz-', '');
  @each $prefix in $prefixes {         // ... and spits out prefixed code
    #{$prefix}#{$property}: $value;
  }
}

// Box sizing
@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  -ms-box-sizing:     $type;
  box-sizing:         $type;
}

// Clearfix
%cf {
  zoom: 1;

  &::before,
  &::after {
    content: ' ';
    display: table;
  }

  &::after {
    clear: both;
  }
}

// Vertical alignment
%vertical-align {
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

// Media query helper
@mixin breakpoint($size: $small, $direction: 'max') {
  $querytype: 'max-width';
  @if $direction == 'min' {
    $querytype: 'min-width';
    $size: $size + 1;
  }

  @media (#{$querytype}: $size) {
    @content;
  }
}

// Gradient maker
@mixin gradient($from: '#dfdfdf', $to: '#f8f8f8', $orient: 'vertical') {
  @if $orient == 'vertical' {
//    background-color: $to;
    background-image: -o-linear-gradient(top, $from, $to);
    background-image: -webkit-linear-gradient(top, $from, $to);
    background-image: linear-gradient(to bottom, $from, $to);
  } @else if $orient == 'horizontal' {
//    background-color: $to;
    background-image: -o-linear-gradient(left, $from, $to);
    background-image: -webkit-linear-gradient(left, $from, $to);
    background-image: linear-gradient(to right, $from, $to);
  }
}

// Font feature settings
@mixin font-feature-settings($feature-list: false) {
	// Only proceed if a list of arguments was passed
  @if $feature-list {
		// Create an empty list for the standard syntax
		$standard: ();

		// Add each listed feature to the standard and old Mozilla values
		@each $feature in $feature-list {
			$standard: append($standard, '#{$feature}' on, comma);
		}

		// Finally, print out the prefixed and non-prefixed code for all of the listed features
		font-feature-settings: $standard;
	}
}
