html,
body,
form,
fieldset,
table,
blockquote,
ul,
ol,
dl {
  margin: 0;
  padding: 0;
}
