$column-width:                    100% / $column-count;

%row {
  @extend %cf;
  clear: both;
  position: relative;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  // grid-row-gap: 1rem;
  // grid-column-gap: 1rem;
}

@mixin col($pos) {
  grid-column: #{$pos};
}

@mixin column($cols) {
  @include box-sizing(border-box);
  float: left;
  padding: 0 ($column-gutter / 2);

  @media (max-width: $small) {
    float: none;
    padding: 0;
  }

  @if $cols > $column-count { // If more columns are asked than setup allows
    outline: 2px solid red; /* Error: more columns than available */
    width: $column-count * $column-width; // Clip to 100% and give red border
    @warn "You're using more columns (#{$cols}) than the system allows (#{$column-count})."; // Throw an error line to the log
  } @else { // You counted thoroughly: here'’'s your code
    width: $cols * $column-width;
  }

  // Modify this to suit your needs
  // For example, if you have more or fewer columns, change the 'drop'
  // here. Also, if you want it to drop at a different ratio,
  // this is the place.
  @media (max-width: $medium) {
    width: $column-count * $column-width; // Same as 100%
  }

  // This assumes that your mobile layout will be one column only.
  // If you want to maintain columns, this is the place to tweak
  // the variables. For example, a forced 50% split can be
  // created this way.
  @media (max-width: $small) {
    padding-left: 0; // Handle padding for mobile with container, not column
    padding-right: 0;
    width: $column-count * $column-width;
  }
}

@mixin offset($offset) {
  // This offsets the active element with the amount of columns you enter.
  // $offset should only take integers and counts columns. We don't do
  // finer granularity than this, to keep things simple and attractive.
  margin-left: $offset * $column-width;

  @media (max-width: $medium) {
    margin-left: 0;
  }
}
