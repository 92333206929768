$background:                      #e0e5e9;
$teal-dark:                       #334242;
$background-dark:                 #333;
$white:                           #fff;
$secondary-white:                 #e3ebf0;
$black:                           #000;
$text-black:                      #222;
$text-white:                      #fefefe;
$turquoise:                       #93b7bb;
$yellow:                          rgb(219, 206, 89);
$button:                          #3858c4;
$red:                             #de4a4a;

// Font sizes
$baseline:                        1.5;
$fontsize:                        1rem;

// Amount of columns in underlying grid system
$column-count:                    7;
$column-gutter:                   1rem;

// Screen sizes for grid cut-off
$small:                           400px;
$medium:                          720px;
$large:                           1024px;
$max-size:                        1440px;
