@charset 'utf-8';
@import  'normalize',
         'reset',
         'variables',
         // comment/uncomment for grid preview
        //  'gridpreview',
         'functions',
         'mixins',
         'grid',
         'typefaces';

:root {
  --page-bg-color: #{$background-dark};
  --page-text-color: #{$text-white};
  --page-text-color-invert: #{$text-black};
  --page-text-color-fade: #{rgba($text-white,.5)};
}

::-webkit-scrollbar {
  background: transparent;
  height: 6px;
  width: 6px;
  padding: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: black(.125);
  border: 1px solid black(.25);
  border-radius: 6px;
  height: 4px;
  margin: 2px;

  &:hover {
    background-color: black(.25);
    cursor: pointer;
  }

  &:window-inactive {
    background: transparent;
    border-color: black(.0625);
  }
}

html {
  background: $background-dark linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0));
  background-color: var(--page-bg-color);
  color: $text-white;
  color: var(--page-text-color);
  // Firefox scroll bar on the body
  scrollbar-color: white(.5) black(.33);
  scrollbar-width: thin;
  min-height: 100%;
  position: relative;

  scroll-behavior: smooth;
  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }

  @include prefix('transition', background 2s ease .25s);
}

body {
  height: 100%;
}

html body * {
  // Firefox scroll bars elsewhere
  scrollbar-color: black(.25) transparent;
}

// $rainbow: linear-gradient(to right, red, #f1a008, #e0d31c, #09aa09, #2450e2, #6614a0, violet);
$rainbow: linear-gradient(to right, hsl(0,100,50), hsl(60,100,50), hsl(120,100,50), hsl(180,100,50), hsl(240,100,50), hsl(300,100,50));
// $rainbow-wrap: linear-gradient(to right, red, #f1a008, #e0d31c, #09aa09, #2450e2, #6614a0, violet, red);
$rainbow-wrap: linear-gradient(to right, hsl(0,100,50), hsl(60,100,50), hsl(120,100,50), hsl(180,100,50), hsl(240,100,50), hsl(300,100,50), hsl(0,100,50));

body {
  @include font-feature-settings(kern liga locl calt frac mark mkmk ccmp);
  font-family: $serif;
  font-kerning: normal;
  font-size: $fontsize;
  line-height: $baseline;
  padding-bottom: 0;
  text-rendering: optimizeLegibility;

  &::before {
    // scss-lint:disable ColorVariable DeclarationOrder
    background-repeat: no-repeat;
    // background-image: -webkit-linear-gradient(to right, red, #f1a008, #e0d31c, #09aa09, #2450e2, #6614a0, violet);
    background-image: $rainbow;

    @include prefix(box-shadow, 0 2px 4px black(.1));
    content: '';
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  &.nav-open {
    overflow: hidden;
  }
}

::selection {
  background: $turquoise;
  color: $white;
}

.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  width: calc(100vw - 2rem);
  max-width: $max-size;
  max-width: calc(#{$max-size} + #{$column-gutter / 2});
}

header {
  padding: 0;
  // background: rgba(51,51,51,.875);
  background: transparent;
  min-height: #{$baseline * 6}rem;
  position: relative;
  // z-index: 50;

  @include breakpoint($medium, 'max') {
    .container {
      max-width: 100%;
      width: auto;
    }
  }

  .nav-toggle {
    float: left;
    width: 64px;
    height: 64px;
    position: relative;
    z-index: 960;

    a {
      display: block;
      width: 40px;
      height: 40px;
      margin: 20px 12px 20px;
      background: transparent url('/assets/images/menu_white.svg') no-repeat 50% 50%;
      background-size: 40px 40px;
      color: transparent;
      text-indent: -99em;
      overflow: hidden;

      body.nav-open & {
        background-image: url('/assets/images/menu_close_white.svg');
      }
    }
  }

  @include breakpoint($medium, 'min') {
    .nav-ui {
      display: none;
    }
  }

  nav {
    @include font-feature-settings(calt kern lnum);
    font-family: $sans-serif;
    font-size: $fontsize;
    font-weight: normal;
    padding: 0;

    @include breakpoint($medium, 'max') {
      display: none;

      body.nav-open & {
        @include box-sizing(border-box);
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        padding-top: 72px;
        padding-bottom: 3rem;
        min-width: 300px;
        width: 92%;
        max-width: 380px;
        height: 100vh;
        overflow-y: auto;
        background: rgba($teal-dark, .925);
        box-shadow:
          0 0 24px -8px rgba(black, .5),
          0 0 2px rgba(black, .25);
        z-index: 950;
        -webkit-backdrop-filter: blur(8px);
        backdrop-filter: blur(8px);
        color: $white;
        li a {
          color: $white;
        }

        .site-logo {
          position: fixed;
          top: 0;
        }
      }
    }

    li a {
      border: 0;
      color: var(--page-text-color);
      display: block;
      padding: 4px 8px;
      position: relative;
      text-decoration: none;

      &:focus,
      &:hover {
        opacity: .75;
      }
    }

    @include breakpoint($medium, 'min') {
      .has-sub-nav > a::after {
        display: inline-block;
        content: '▼';
        font-size: 12px;
        padding-left: 8px;
        opacity: .5;
      }
    }

    .sub-nav li a {
      color: $text-white;
    }
    .sub-nav li.sub-nav--nav-split {
      margin-top: 4px;
      padding-top: 4px;
      border-top: 1px solid rgba($text-white,.25);
    }

    @include breakpoint($medium, 'max') {
      ul.business {
        padding-bottom: #{$baseline*2}rem;
      }

      li a {
        font-variant-caps: all-small-caps;
      }
      .sub-nav {
        li a {
          font-variant-caps: normal;
          padding-left: 1.5rem;
        }
      }
    }

    @include breakpoint($medium, 'min') {
      display: flex;
      flex-flow: row wrap;

      ul {
        display: inline-flex;
        text-align: center;
        flex: 2 auto;
        margin: 0;
  
        &.business {
          text-align: right;
          flex: 1 auto;
  
          li:first-of-type {
            margin-left: auto;
          }
        }
  
        li {
          display: inline-flex;
          position: relative;
          margin-right: 8px;
  
          .sub-nav {
            display: block;
            opacity: 0;
            margin-top: -8px;
            visibility: hidden;
            position: absolute;
            min-width: 14rem;
            top: 80%;
            z-index: 800;
            background: rgba(61,61,61,.95);
            border-radius: 8px;
            transition: all ease-out .2s;
            @include prefix(
              box-shadow, 0 4px 12px black(.2),
                          0 6px 24px black(.15),
                          0 0 0 1px black(.1)
            );

            ul {
              display: block;
              padding: 0;
            }
            li {
              display: block;
              text-align: left;
              margin-right: 0;
  
              a {
                padding: 8px 16px;
                line-height: $baseline;
  
                &:hover {
                  opacity: 1;
                  background: rgba($black, .1);
                }
              }

              &:first-of-type a {
                border-radius: 8px 8px 0 0;
              }
              &:last-of-type a {
                border-radius: 0 0 8px 8px;
              }
            }
          }
  
          &:hover .sub-nav {
            opacity: 1;
            visibility: visible;
            margin-top: 0;
          }
  
          &.active > a::before {
            border-bottom: 2px solid;
            border-radius: 2px;
            bottom: #{$baseline/2}rem;
            content: '';
            display: block;
            left: 5%;
            opacity: .75;
            position: absolute;
            width: 90%;
          }
        }

        li a {
          padding-top: #{$baseline/2}rem;
          line-height: $fontsize * $baseline * 3;
        }
      }
    
    }
  
  }


  .cart-item-count {
    background: black(.15);
    border-radius: 50%;
    display: inline-block;
    height: 1.75rem;
    line-height: 1.65;
    text-align: center;
    width: 1.75rem;
  }

  &:empty {
    display: none;
  }

  .shopping-cart-persistent {
    background: var(--page-text-color);
    box-shadow: 0 2px 2px rgba(0,0,0,0.25), 0 1px 1px rgba(0,0,0,0.1);;
    border-bottom-left-radius: 4px;
    color: var(--page-text-color-invert);
    cursor: pointer;
    height: 2.58rem;
    right: 0;
    position: fixed;
    top: 0;
    width: auto;
    z-index: 900;

    top: -3.5rem;
    transition: all .2s ease;

    &.visible {
      top: 0;
      transition: all .2s ease;
    }

    a {
      display: inline-block;
      padding: .25rem 1rem .33rem 1.25rem;
      line-height: 2rem;
      color: var(--page-text-color-invert);
    }

    a:hover {
      opacity: .875;
    }

    a span {
      background: var(--page-text-color-invert);
      color: var(--page-text-color);
      border-radius: 50%;
      display: inline-block;
      height: 1.75rem;
      line-height: 1.65;
      text-align: center;
      width: 1.75rem;
      margin-left: .25rem;
    }

    @include breakpoint($medium, 'max') {
      display: none;
    }
  }

  .shopping-cart-link {
    cursor: pointer;
  }

  #js-shop-permanent {
    background: var(--page-text-color);
    box-shadow: 0 2px 2px rgba(0,0,0,0.25), 0 1px 1px rgba(0,0,0,0.1);;
    border-radius: 0 0 4px 4px;
    color: var(--page-text-color-invert);

    @include prefix(transition, all .2s ease-out);

    font-family: $sans-serif;
    font-size: 1.25rem;
    height: 44px;
    left: 50%;
    line-height: 44px;
    opacity: 0;
    position: fixed;
    text-align: center;
    top: -48px;
    transform: translateX(-50%);
    width: 300px;
    z-index: 960;

    &.pop-up {
      opacity: 1;
      top: 0;
    }

    &.pop-in {
      opacity: .001;
      top: -48px;
    }
  }

  .site-logo {
    position: relative;
    display: inline-flex;
    height: 64px;
    text-align: center;
    text-decoration: none;
    width: 64px;
    z-index: 955;
    margin: #{$baseline / 2}rem 16px #{$baseline / 2}rem 8px;

    h1 {
      background-image: url('/images/logo_white.svg');
      background-position: 50% 50%;
      background-size: 64px auto;
      background-repeat: no-repeat;
      display: block;
      height: 64px;
      margin: 0;
      overflow: hidden;
      text-indent: 99rem;
      white-space: nowrap;
      width: 64px;
    }

    // Rainbow logo for Pride Month
    // &:focus,
    // &:hover {
    //   mix-blend-mode: unset;

    //   h1 {
    //     background-image: url('/assets/images/logo_rainbow.svg');
    //   }
    // }
    // Swap with above
    &:focus,
    &:hover {
      opacity: .875;
    }

  }
}

@include breakpoint($medium, 'max') {
  body.nav-open nav .site-logo {
    margin-left: 72px;
  }
}

main {
  -moz-osx-font-smoothing: unset;
  -webkit-font-smoothing: subpixel-antialiased;
  margin: 0;

  .post-nav {
//    max-width: 32rem;

    &::after {
      clear: both;
      content: ' ';
      display: table;
    }

    li {
      font-family: dover-sans-display-regular;
      font-size: 1.5rem;
      line-height: $baseline;
      list-style: none;

      em,
      i {
        font-family: dover-sans-display-italic;
      }
    }

    .prev {
      float: left;
    }

    .next {
      float: right;
    }
  }
}

footer {
  font-family: $sans-serif;
  margin: #{$baseline * 6}rem 0 #{$baseline * 2}rem;
  opacity: .666;
  color: var(--page-text-color);

  dl {
    @include column(2);
    margin-bottom: #{$baseline}rem;

    dt {
      @include font-feature-settings(kern liga locl calt frac mark mkmk ccmp c2sc);
      letter-spacing: .1rem;
      text-transform: uppercase;
    }

    dd {
      margin: 0;
      padding: 0;
    }

    ul {
      padding: 0;
    }

    li {
      list-style: none;
    }
  }

  @include breakpoint($medium, 'max') {
    padding: 0;

    dl, p {
      clear: none;
      padding: 0 #{$column-gutter / 2};
    }
  }

  .form-inline {
    input {
      @include box-sizing();

      background: white(.25);
      border: 1px solid;
      border-radius: 4px;
      color: inherit;
      display: inline-block;
      height: #{$baseline}rem;
      line-height: $baseline;
      margin: 0 0 #{$baseline/2}rem;
      padding: .05rem .4rem .1rem;
      vertical-align: top;
      width: 9rem;
    }

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder {
      color: inherit;
    }

    .button-inline {
      @include box-sizing();
      @include prefix(box-shadow, none);

      display: inline-block;
      height: 1.6rem;
      line-height: 1.6rem;
      overflow: hidden;
      padding: 0;
      position: relative;
      text-indent: -99em;
      vertical-align: top;
      width: 2rem;

      &::after {
        content: attr(data-label);
        left: 0;
        position: absolute;
        text-align: center;
        text-indent: 0;
        width: 2rem;
      }
    }
  }

  @include breakpoint($medium, 'min') {
    p {
      clear: both;
      padding: 0 #{$column-gutter / 2};
    }
  }
}

//
//  Typography
//

h2 {
  font-family: dover-serif-display-regular, serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: #{$baseline * 4 / 2.5}rem;
  margin: #{$baseline}rem 0 #{$baseline * 2}rem;
  max-width: 40rem;

  em,
  i {
    font-family: dover-serif-display-italic, serif;
    font-style: italic;
  }
}

h3 {
  font-family: dover-sans-display-regular, Gill Sans, Gill Sans MT, sans-serif;
  font-size: 1.5rem;
  line-height: #{$baseline * 1.5}rem;
  font-weight: normal;
  margin: #{$baseline * 2}rem 0 #{$baseline / 2}rem;

  &:first-of-type {
    margin-top: #{$baseline}rem;
  }

  aside & {
    margin-top: #{$baseline}rem;
  }
}

h4 {
  font-family: $sans-serif;
  font-size: 1rem;
  font-weight: normal;
  @include font-feature-settings(kern smcp c2sc calt);
  margin: #{$baseline / 2}rem 0 0;
}

.back-link {
  font-family: $sans-serif;
  margin: 2rem 0 1rem;

  a {
    background: rgba($black, .2);
    border-radius: .4rem;
    border: none;
    color: rgba($white, .875);
    color: var(--page-text-color);
    line-height: 2;
    margin: 0 0 .25rem;
    padding: .35rem .5rem .3rem;
    text-decoration: none;
    white-space: nowrap;

    &:focus,
    &:hover {
      background: rgba($black, .333);
      // padding: .35rem .5rem .3rem;
    }
  }
}

p {
  margin: 0 0 #{$baseline / 2}rem;
  max-width: 32rem;
}

.family-body p,
.family-body ol,
.news-body p {
  @include breakpoint($medium, 'min') {
    max-width: calc((#{$column-width} * 3) - #{$column-gutter});
  }
}

.family-body p,
.family-body h2,
.family-body h3,
.family-body h4,
.news-body p,
.news-body h2,
.news-body h3,
.news-body h4 {
  @include breakpoint($medium, 'min') {
    @include offset(1);
  }
  padding: 0 #{$column-gutter / 2};
}

.family-body ol {
  @include breakpoint($medium, 'min') {
    @include offset(1);
  }
}

.family-body p + h3 {
  margin-top: 6rem;
}

.family-body figcaption p,
.news-body figcaption p {
  margin-left: 0;
  padding: 0;
}

.also a {
  opacity: .5;
}

%em-note {
  background: rgba($yellow, .25);
  font-family: $sans-serif;
  font-style: normal;
  font-weight: normal;
  padding: 3px 2px;
}

main a,
.technical-information a,
form a,
footer a {
  @include font-feature-settings(kern liga locl lnum calt frac mark mkmk ccmp);

  color: inherit;
  color: var(--page-text-color);
  padding: .1rem 0 .05rem;
  text-decoration: none;
  border-bottom: 1px solid var(--page-text-color-fade);

  &:focus,
  &:hover {
    border-color: var(--page-text-color);
  }
}

// blockquote {
//   border-bottom: 1px solid lighten($black, 90%);
//   border-top: 1px solid lighten($black, 90%);

//   font-family: dover-sans-display-regular, Gill Sans, Gill Sans MT, sans-serif;
//   font-size: 1.5rem;
//   line-height: 1.25;

//   margin: 1rem 0;
//   max-width: 16rem;

//   p {
//     margin: .25rem 0;
//   }
// }

ol {
  max-width: 32rem;
  padding-left: 1.5rem;
  list-style-position: outside;

  li {
    padding-left: 1rem;
  }

  ol {
    li {
      list-style: lower-latin;
    }
  }
}

dl {
  dt {
    font-family: $sans-serif;

  }

  dd {
    margin-left: 1.5rem;
  }
}

figure {
  margin: #{$baseline * 3}rem #{$column-gutter / 2};
  max-width: $max-size;
  width: 100%;

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  svg,
  svg image {
    display: block;
    width: 100%;
  }

  figcaption,
  .caption {
    font-family: $sans-serif;

    opacity: .66;

    h5 {
      margin: 0;
      display: inline-block;
      width: 3rem;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .embed-youtube {
    position: relative;
    padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
    /* padding-bottom: 62.5%; - 16:10 aspect ratio */
    /* padding-bottom: 75%; - 4:3 aspect ratio */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-left: -.5rem;
  }

  .embed-youtube iframe,
  .embed-youtube object,
  .embed-youtube embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.portrait {
    padding: 0 ($column-gutter / 2);
    float: right;
    // margin: 0 0 2rem 1.5rem;
    // max-width: $large / 2;
    // width: 28%;
    width: calc(#{2*$column-width} - #{$column-gutter});
    margin-left: 0;
    margin-top: -1rem;
    margin-right: calc(#{$column-width});

    @include breakpoint($large, 'max') {
      display: block;
      margin: -1rem 0 2rem ($column-gutter / 2);
      width: calc(#{2*$column-width} - #{$column-gutter});
      // max-width: #{( (3/7) * 100 )}vw;
    }
    @include breakpoint($medium, 'max') {
      clear: both;
      float: left;
      width: auto;
      margin: 1.5rem 0;
      max-width: #{( (3/5) * 100 )}vw;
    }
    figcaption {
      opacity: .5;
      padding: .5rem 0;
    }
  }

  &.author {
    // border: 2vw solid $white;
  }

  table {
    @include font-feature-settings(kern liga locl lnum pnum calt frac mark mkmk ccmp);

    background: white(.05);
    border-radius: 4px;
    margin: .75rem 0;
    width: 100%;

    td,
    th {
      padding: .1rem .25rem;
    }

    thead td {
      background: white(.05);
    }

    tr:nth-of-type(even) td,
    tr:nth-of-type(even) th {
      background: black(.05);
    }

    thead td,
    tr th {
      font-family: $sans-serif;
      font-weight: normal;
  
    }

    tr th {
      @include font-feature-settings(kern liga locl lnum tnum calt frac mark mkmk ccmp);
    }
  }
}

.before-portrait {
  // Ugly hack to make the portrait float side by side with the text
  float: left;

  @include breakpoint($medium, 'max') {
    float: none;
  }
}


code {
  color: lighten($yellow, 20%);
  font-family: Lucida Console, Courier, monospace;
  font-size: .95em;
}


// Standard layout items

.page-title-card {
  @include column(6);

  .page-colophon & {
    @include offset(1);
  }
}

.page-lede {
  @include column(7);
}

.page-body {
  @include column(4);
  @include offset(1);
}

.body--license,
.body--info,
.body--contact {
  @include column(3);
  @include offset(0);
}

.about-page .page-title-card,
.page-contact .page-title-card {
  @include offset(1);
}

.body--info,
.body--contact {
  @include offset(1);

  hr {
    margin-left: 0;
  }

  p.item-lede {
    font-family: $sans-serif;

    font-size: 1.25em;
    line-height: $baseline;
  }
}


// News
.news-items {
  @extend %row;

  .post-text {
    @include column(4);
    @include offset(1);
  }

  figure.post-image {
    margin: 1rem 0 #{$baseline * 2}rem;
    max-width: 100%;

    img {
      width: 100%;
      max-width: auto;
    }
  }

  @include breakpoint($medium, 'max') {
    .post-text {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .post {
    @extend %cf;

    margin-bottom: 1rem;
    padding-bottom: #{$baseline * 4}rem;
    // padding-left: .5rem;
    // padding-right: .5rem;

    // &:first-of-type {
    //   border-radius: .4rem .4rem 0 0;
    // }

    // &:last-of-type {
    //   border-radius: 0 0 .4rem .4rem;
    // }

    // &:only-of-type {
    //   border-radius: .4rem;
    // }
  }

  h3 {
    font-size: 2rem;
    line-height: 2.8rem;
    margin: 0 0 .8rem;

    a {
      padding: 0;
      text-decoration: none;
      color: inherit;
      border: none;

      &:focus,
      &:hover {
        padding: 0;
      }
    }
  }

  .read-more-link {
//    border-radius: .2rem;
    opacity: .5;
    padding: .3rem .3rem .2rem;
    border-bottom: 1px solid;
    color: inherit;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }
}

.post-meta {
  font-family: $sans-serif;
  opacity: .66;
}

.post .post-text .post-meta {
  margin: .8rem 0 0;
  font-size: 1.25em;
  letter-spacing: .02em;
  font-feature-settings: 'smcp', 'c2sc', 'kern', 'calt';
}

.news-main-article .post-meta {
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.news-main-article {
  div.footnotes {
    @include offset(1);
  }
  div.footnotes p {
    margin-left: 0;
    max-width: 32rem;
  }
}

hr {
  // clear: both;
  border: 0;
  border-bottom: 1px solid;
  height: 1px;
  margin: #{$baseline * 3}rem 0;
  width: $column-width;
  opacity: .5;

  @include breakpoint($small, 'min') {
    margin-left: $column-width;
  }
}

.news-main-article {
  h2 {
    font-size: 3rem;
    line-height: #{$baseline * 7 / 3}rem;
    max-width: 48rem;
  }

  p.item-lede {
    font-family: $sans-serif;

    font-size: 1.25em;
    line-height: $baseline;
  }

  figure.fig-aside {
    clear: right;
    float: right;
    width: calc(#{$column-width * 2.5} - #{$column-gutter});
    margin-right: #{$column-gutter / 2};
    margin-left: $column-gutter / 2;

    p {
      max-width: 100%;
    }

    @include breakpoint($medium, 'max') {
      width: calc( ( (2/5) * 100% ) - #{$column-gutter});
    }
  }
}



//
// Footnotes
//

sup {
  font-size: 1rem;
  vertical-align: baseline;

  a {
    @include font-feature-settings(kern liga locl smcp c2sc calt mark mkmk ccmp);

    background: black(.15);
    border: 0;
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-family: $sans-serif;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    margin: 2px 1px 0 2px;
    padding: 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    width: 16px;

    &:focus,
    &:hover {
      background: black(.2);
      color: $white;
      padding: 0;
    }
  }
}

.footnotes {
  border-top: 1px dotted;
  font-family: $sans-serif;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  max-width: 32rem;
  opacity: .75;
  padding: 1rem 0 0;

  ol {
    margin-left: 1rem;
    list-style-position: outside;
  }

  [href^='#fnref:'] {
    border: 0;
    margin-left: .5rem;
    text-decoration: none;
  }
}


//
// Forms
//

form {
  @include font-feature-settings(calt frac kern liga pnum);

  font-family: $sans-serif;

  fieldset {
    border: 0;
    margin: 0 0 1.5rem;
    padding: 0;
  }

  .form-honey {
    height: 1px;
    opacity: .1;
    overflow: hidden;
    width: 1px;
  }

  p {
    margin: 0;
  }

  .form-item {
    display: block;
    margin: 0 0 #{$baseline}rem !important;
    max-width: $medium;
  }

  label {
    display: block;
  }

  li {
    list-style: none;
    margin-bottom: 1rem;
  }
}

label {
  cursor: pointer;
  margin-top: #{$baseline / 4}rem;
  margin-bottom: #{$baseline / 4}rem;
}

input,
textarea,
select,
button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $white;
  border: 1px solid rgba($text-black, .5);
  border-radius: 4px;
  color: $text-black;
  font-family: inherit;
  font-size: inherit;
  padding: #{$baseline / 4}rem .33rem #{$baseline / 4}rem;
}

input:focus,
textarea:focus,
select:focus {
  @include prefix(box-shadow, 0 0 .75rem rgba(black, .5));

  border-color: $text-black;
  border-color: var(--page-text-color);
  outline: none;
}

[type='checkbox'] {
  border-color: rgba($text-black, .5);
  height: #{$baseline}rem;
  position: relative;
  vertical-align: top;
  width: #{$baseline}rem;
  border-radius: 0;

  &::after {
    // @include prefix(
    //   transition,
    //   background-position .15s ease-out
    // );

    background: url('/assets/images/checkmark.svg') no-repeat 1.1rem 50%;
    background-size: 1rem auto;
    color: $text-black;
    content: ' ';
    display: block;
    font-size: 1.5rem;
    left: 0;
    line-height: 1.1rem;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.1rem;
  }

  &:checked::after {
    background-position: 50% 50%;
    color: white(0);
    content: '√';
  }
}

[type='radio'] {
  border-color: rgba($text-black, .5);
  border-radius: 1rem;
  height: #{$baseline}rem;
  margin-top: 1px;
  position: relative;
  vertical-align: top;
  width: #{$baseline}rem;

  &::after {
    // @include prefix(
    //   transition,
    //   background-position .15s ease-out
    // );

    background-size: 1rem auto;
    color: $text-black;
    content: ' ';
    display: block;
    font-size: 1.5rem;
    left: 0;
    line-height: .6;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.3rem;
  }

  &:checked {
    border-color: $text-black;
  }

  &:checked::after {
    content: '•';
  }
}

[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
  height: 8px;
  padding: #{$baseline / 4}rem 0;
  border-left: 1px solid $text-black;
  border-right: 1px solid $text-black;
  border-color: var(--page-text-color);

  &:focus {
    box-shadow: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    transition: 0.2s;
    background: $text-black;
    background-color: var(--page-text-color);
  }
  &::-webkit-slider-thumb {
    box-shadow: 0 2px 3px rgba(0,0,0,.2);
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background: $text-black;
    background-color: var(--page-text-color);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;

    @include breakpoint($medium, 'max') {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-top: -11px;
    }
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    transition: 0.2s;
    background: $text-black;
    background-color: var(--page-text-color);
  }
  &::-moz-range-thumb {
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    height: 12px;
    width: 12px;
    border-radius: 12px;
    border: none;
    background: $text-black;
    background-color: var(--page-text-color);
    cursor: pointer;

    @include breakpoint($medium, 'max') {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-top: -11px;
    }
  }
  &::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    transition: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: $text-black;
    background-color: var(--page-text-color);
    border-radius: 8px;
  }
  &::-ms-fill-upper {
    background: $text-black;
    background-color: var(--page-text-color);
  }
  &::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    height: 12px;
    width: 12px;
    border-radius: 12px;
    background: $text-black;
    background-color: var(--page-text-color);
    cursor: pointer;

    @include breakpoint($medium, 'max') {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin-top: -11px;
    }
  }
  &:focus::-ms-fill-lower {
    background: $text-black;
    background-color: var(--page-text-color);
  }
  &:focus::-ms-fill-upper {
    background: $text-black;
    background-color: var(--page-text-color);
  }
}

select {
  cursor: pointer;
  padding: #{$baseline / 4}rem 1.5rem #{$baseline / 4}rem .33rem;
  width: auto;
}

.form-select-item {
  display: block;
  max-width: 24rem;
  position: relative;
  width: 96%;

  &::after {
    background: url('/assets/images/dropdown.svg') no-repeat 50% 50%;
    bottom: 0;
    content: ' ';
    display: block;
    height: 2.25rem;
    line-height: 2.25rem;
    pointer-events: none;
    position: absolute;
    right: .25rem;
    width: 2rem;
    z-index: 600;
  }

  select {
    @include prefix(
      transition,
      all .25s ease-in-out
    );
    @include prefix(
      box-shadow,
      inset 0 -2px 1px white(.5),
      0 2px 8px rgba($text-black, .25),
      0 1px 2px rgba($text-black, .125)
    );

    border-radius: 4px;
    margin-top: .25rem;
    position: relative;
    width: 100%;
    z-index: 550;
  }
}

.form-radio-set {
  li {
    margin-bottom: .5rem;
  }
}

textarea {
  height: 9rem;
  max-width: 24rem;
  width: 90%;
}

button,
.button {
  // @include gradient(black(0), black(.15));
  @include prefix(
    box-shadow,
    0 2px 2px rgba(black, .25),
    0 1px 1px black(.1)
  );

  background-color: darken($button, 15%);
  border-color: darken($button, 25%);
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $sans-serif;
  padding: .35rem 1rem .35rem;
  text-decoration: none;

  &:focus,
  &:hover {
    @include prefix(
      box-shadow,
      0 2px 6px rgba($black, .5),
      0 1px 1px black(.15)
    );

    background-color: darken($button, 20%);
    border-color: darken($button, 30%);
  }

  &:active {
    @include prefix(
      box-shadow,
      0 1px 1px rgba($black, .2),
      0 0 1px black(.1)
    );
    background-color: darken($button, 25%);
    border-color: darken($button, 35%);
  }

  &.disabled,
  &[disabled] {
    cursor: default;
    opacity: .6;
    pointer-events: none;
  }
}

button:focus,
.button:focus {
  border-color: black;
  border-color: var(--page-text-color);
}

@keyframes rainbow-hover {
  from { background-position: 0% 0%; }
  to { background-position: -200% 0%; }
}

.primary {
  // @include prefix(
  //   box-shadow,
  //   0 2px 2px rgba($button, .125),
  //   0 1px 1px black(.1)
  // );

  // background-color: $button;
  // border-color: darken($button, 5%);
  float: right;

  // &:focus {
  //   border-color: darken($button, 15%);
  // }

  // &:focus,
  // &:hover {
  //   @include prefix(
  //     box-shadow,
  //     0 2px 6px rgba($button, .4),
  //     0 1px 1px black(.15)
  //   );

  //   background-color: darken($button, 5%);
  //   border-color: darken($button, 15%);
  // }

  position: relative;
  z-index: 30;

  &:hover::after {
    position: absolute;
    z-index: 20;
    content: " ";
    width: calc(100% + 2px);
    left: -1px;
    bottom: -1px;
    height: 4px;
    display: block;
    background: $rainbow-wrap;
    background-repeat: repeat;
    background-position: 0% 0%;
    background-size: 200%;
    border-radius: 0 0 4px 4px;
    animation: rainbow-hover 3s linear infinite;
  }
}

.hide {
  display: none;
}


//
//  Front page
//

.page-front {
  main {
    margin-bottom: 4rem;
    margin-top: 1rem;
    min-height: 33vh;

    h2 {
      max-width: 100%;
      text-align: center;

      @include breakpoint($medium, 'max') {
        br {
          display: none;
        }
      }
    }
  }
}

#SVG_scene {
  html.no-js & {
    display: none;
  }
  * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@keyframes appear {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

svg .svg--random-letter {
  animation: appear .25s ease-out;
}

svg :not(.svg--font-name) {
  transition: fill .33s ease;
}
a:hover svg :not(.svg--font-name) {
  fill: rgba(255,255,255,.33);
}

// .front-page-promo {
//   text-align: center;
//   font-size: 3rem;
//   font-family: $sans-serif;
//   position: relative;

//   @include breakpoint($medium, 'min') {
//     min-height: 40vh;
//   }

//   a {
//     border: none;
//   }
//   .promo-image {
//     position: relative;
//     width: 100%;
//     height: auto;
//     height: calc(#{$small} * (13/30));
//     @include breakpoint($medium, 'min') {
//       height: calc(#{$medium} * (13/30));
//     }
//     @include breakpoint($large, 'min') {
//       height: calc(#{$large} * (13/30));
//     }
//     @include breakpoint($max-size, 'min') {
//       height: calc(#{$max-size} * (13/30));
//     }
//     display: block;
//     max-width: $max-size;
//     margin: 2rem auto;
//     color: transparent;
//     transition: opacity 2s ease;
//     opacity: 1;
//   }
//   h3 {
//     display: inline-block;
//     margin: 1rem auto;
//     padding: .3rem .9rem;
//     border-radius: .33rem;
//     background: $text-white;
//     color: $background-dark;
//     transition: opacity 2s ease;

//     &:hover {
//       transform: scale(1.03);
//     }
//   }
//   img[data-src] {
//     opacity: 0;
//   }
//   img[data-src] + h3 {
//     opacity: 0;
//   }
//   img + h3 {
//     opacity: 1;
//   }
// }

// @keyframes loader-rotate {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// html.js-loading .front-page-promo::before {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 2rem;
//   height: 2rem;
//   margin-left: -1rem;
//   border: 6px dotted $text-white;
//   border-color: rgba($text-white, 0) rgba($text-white, .33) rgba($text-white, .66) rgba($text-white, 1);
//   content: '';
//   border-radius: 2rem;
//   animation: loader-rotate .5s linear infinite;
// }

// The front page font list
.font-overview {
  list-style: none;
  margin: 1rem 0 2rem;

  &.all-typefaces > li {
    margin-bottom: 1rem;
  }
  &.all-typefaces .front-sample {
    margin-bottom: 1rem;
  }

  &.custom {
    a:hover {
      opacity: .75;
    }
    .style-info p {
      float: none;
      padding-bottom: 1rem;
    }
    h3 {
      margin-bottom: 0;
    }
    img {
      width: auto;
      max-width: 100%;
      height: 4rem;
    }
  }

  li {
    clear: both;
    margin: 0 0 .25rem;
    position: relative;
  }

  a {
    border: 0;
    display: block;
    margin: 0 auto;
    padding: 0;
    text-decoration: none;

    &:hover {
      padding: 0;
    }
  
    .is-front &:hover {
      padding: inherit;
    }

    p {
      margin: 0;
      max-width: 100%;
    }

    .row {
      display: block;
    }

    .alphabet {
      font-size: .5em;
    }

    &:focus,
    &:hover {
      background: none;
    }
  }

  .sample {
    background: $white;
    color: $background-dark;
    font-size: 2rem;
    overflow: hidden;
    padding: 0 1rem;
    white-space: nowrap;
  }

  .style-info {
    @include font-feature-settings(calt kern lnum liga);

    color: white(.75);
    font-family: $sans-serif;

    margin: 0 auto;
    max-width: $max-size;
    padding: .2rem .5rem;

    h3 {
      color: $text-white;
    }

    @include breakpoint($medium, 'min') {
      p {
        float: left;
        width: 50%;
      }
      .style-quicklinks {
        text-align: right;
        float: right;

        a {
          margin: 0 0 0 1rem;
        }
      }
    }

    a {
      color: inherit;
    }

    .style-quicklinks {
      a {
        display: inline-block;
        margin: 0 .5rem 0 0;
        &:hover {
          color: $text-white;
        }
      }
    }

  }

  .front-sample {
    clear: both;
  }

  .overview-style-list {
    max-width: $max-size;
    margin: 0 auto;
    color: $text-white;

    li {
      font-size: 6vw;
      list-style: none;
      padding: 0 .5rem;
      border-bottom: 1px solid white(.25);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @include breakpoint($medium, 'min') {
        font-size: 3rem;
      }
    }
  }

}


//
//  Navigation on the type page
//

.type-page-nav,
.faq-nav {
  // scss-lint:disable DeclarationOrder
  @extend %cf;

  font-family: $sans-serif;
  margin: #{$baseline * 8}rem auto #{$baseline * 4}rem;
  margin: 12.5vh auto 6.75vh;
  clear: left;
  padding: 0 #{$column-gutter / 2};

  header {
    position: relative;
    min-height: auto;
  }

  @include breakpoint($medium, 'max') {
    padding-bottom: 12px;
  }

  h2 {
    margin-bottom: #{$baseline}rem;
    font-size: 10vw;
    line-height: 1;
    max-width: 75vw;
    font-size: clamp(12px, 10vw, 7rem);

    // Custom samples use SVG, not fonts
    img {
      margin: 0;
      display: block;
      width: auto;
      max-width: 70%;
      height: auto;
      max-height: 135px;

      @include breakpoint($medium, 'max') {
        max-width: 100%;
      }
    }
  }

  .button {
    background: var(--page-text-color);
    color: var(--page-text-color-invert);
  }

  @include breakpoint($medium, 'min') {    
    a.jump-to-purchase {
      position: absolute;
      bottom: .95rem;
      right: 0;
    }
  }

  .item-not-for-sale {
    background: var(--page-text-color);
    color: var(--page-text-color-invert);
    margin-top: 0;
    opacity: .66;
    border-radius: 4px;
    padding: .35rem 1rem .35rem;
    cursor: default;
    user-select: none;
  
    @include breakpoint($medium, 'min') {    
      position: absolute;
      bottom: 2rem;
      right: 0;
    }
  }

  .type-lede {
    margin-top: #{$baseline}rem;
    max-width: 32rem;
  }

  ul {
    font-size: 1rem;
    margin: #{$baseline/2}rem 0 #{$baseline/2}rem;
    // text-align: center;

    li {
      list-style: none;
      margin-bottom: .25rem;

      // a {
        // background: rgba($black, .2);
        // border-radius: .4rem;
        // color: rgba($white, .875);
        // display: block;
        // padding: .25rem .5rem .35rem;
        // text-decoration: none;

        // &:focus,
        // &:hover {
          // background: rgba($black, .333);
          // padding: .25rem .5rem .35rem;
        // }
      // }
    }
  }

  @include breakpoint($medium, 'min') {
    // h2 {
    //   font-size: 8vw;
    //   font-size: clamp(8vw, 8vw, 7rem);
    // }

    ul {
      font-size: 1.5rem;
      font-family: dover-sans-display-regular;
      margin: 4rem 0 3rem;
      // float: left;
      // padding-top: 1.7rem;

      li {
        // border-radius: .2rem .2rem 0 0;
        display: inline-block;
        margin: 0 2rem 0 0;
        // overflow: hidden;
        // padding: .25rem 0;

        a {
          display: inline;
        }
      }
    }
  }
  // @include breakpoint($max-size, 'min') {
  //   h2 {
  //     font-size: 7rem;
  //   }
  // }
}

.page-faq {
  h2 {
    margin-top: 25vh;
  }
  
  .faq-nav {
    margin-top: 0;
    ul {
      padding-top: 0;
    }
  }

  h4 {
    margin: #{$baseline*1.5}rem 0 #{$baseline/2}rem;
  }
}


//
//  Font styles overview (for bigger families
//

div.font-styles-overview {
  @extend %row;
  margin: 1rem auto 6rem;

  @include breakpoint($medium, 'min') {
    margin: 1.5rem auto 10rem;
  }

  li {
    list-style: none;
  }
}

div.font-styles-overview__subset {
  @include column(3);
  line-height: 1.33;

  a {
    font-size: 1.5rem;
  }

  h4 {
    border-bottom: 1px solid var(--page-text-color-fade);
    padding-bottom: .5em;
    margin-bottom: .3em;
    opacity: .66;
  }

  @include breakpoint($medium, 'min') {
    a {
      font-size: 2rem;
    }
  }

  @include breakpoint($large, 'min') {
    @include column(2);
  }
}


//
//  Font styles preview
//

.font-styles {
  margin: 1.5rem auto;
  max-width: 96%;
  width: calc(100vw - 3rem);

  .edit-notice {
    display: none;
  }

  @include breakpoint($large, 'min') {
    max-width: $max-size;
  }

  li {
    list-style: none;
    margin: 0 0 clamp(1rem, 2vw, 4rem);
    text-align: center;
    text-align: left;
    position: relative;

    html.js-loading & {
      opacity: .5;
      cursor: progress;
    }

    h4 {
      font-family: $sans-serif;
      font-size: 1rem;
      font-weight: normal;
      opacity: .5;
      margin: 0;
      @include font-feature-settings(calt frac kern liga locl);
    }
    &:hover h4 {
      opacity: 1;
    }

    p {
      @include font-feature-settings(calt frac kern liga locl);

      // position: relative;
      font-size: 2rem;
      line-height: 1.35;
      margin: 0;
      max-width: 100%;

      // @include breakpoint($medium, 'min') {
      //   font-size: 5vw;
      // }
    }

    img {
      display: block;
      width: 100%;
      max-width: $max-size;
      height: auto;
      margin: 0 auto;
    }
  }
  .phrase_long {
    display: none;
  }
  .phrase_short {
    font-size: 12vw;
    line-height: 1.1;
    display: block;
    overflow: hidden;
    padding: .25rem 0;
    text-align: center;
  }
  .ttc-tester-ui span.scale-label {
    position: absolute;
    text-align: left;
    display: inline-block;
    margin-top: -.125rem;
    margin-left: .75rem;
    font-style: normal;
    font-feature-settings: 'kern', 'calt', 'c2sc', 'smcp';
    letter-spacing: .06em;
  }
  .ttc-tester-ui [attr-scale='phrase_long'] {
    display: none;
    & + span {
      display: none;
    }
  }

  @include breakpoint($medium, 'min') {
    @keyframes edit-hover-float {
      0% {
        transform: translateY(0) rotate(5deg);
      }
      50% {
        transform: translateY(3rem) rotate(5deg);
      }
      100% {
        transform: translateY(0) rotate(5deg);
      }
    }

    .edit-notice {
      font-family: dover-sans-text-regular, tiny-grotesk-medium, sans-serif;
      background: color-mix(in srgb, var(--page-text-color-invert) 80%, transparent);
      color: var(--page-text-color);
      box-shadow: 0 3px 12px color-mix(in srgb, var(--page-text-color) 20%, transparent);
      backdrop-filter: blur(.15em);

      border-radius: 2rem;
      font-size: 1.25rem;
      padding: .2em .8em;
      display: inline-block;
      position: absolute;
      top: 45vh;
      left: calc(100vw - 18rem);
      pointer-events: none;
      position: sticky;
      z-index: 90;

      animation: edit-hover-float 4s ease-in-out infinite;
    }

    li {
    }

    .phrase_long {
      display: block;
      line-height: 1.1;
      font-size: 7.4vw;
      font-size: clamp(12px, 7.4vw, 112px);
      text-align: center;
    }
    .phrase_long.sample__text-type {
      font-size: 3.75vw;
      font-size: clamp(12px, 3.65vw, 55px);
      text-align: left;
    }
    .phrase_short {
      display: none;
    }
    .ttc-tester-ui {
      position: absolute;
      left: 320px;
      top: .2rem;
    }
    .ttc-tester-ui [attr-scale='phrase_short'] {
      display: none;
      & + span { display: none; }
    }
    .ttc-tester-ui [attr-scale='phrase_long'] {
      display: inline-block;
      & + span { display: inline-block; }
    }
  }

  // @media screen and (min-width: $medium) and (max-width: $max-size) {
  //   .phrase_long {
  //     font-size: 7.4vw;
  //   }
  //   .phrase_long.sample__text-type {
  //     font-size: 3.75vw;
  //   }
  // }

  // @include breakpoint($max-size, 'min') {
  //   .phrase_long {
  //     font-size: 110px;
  //   }
  //   .phrase_long.sample__text-type {
  //     font-size: 60px;
  //   }
  // }


  @include breakpoint($max-size, 'min') {
  }
  &.js h4 {
    margin-bottom: .33rem;
  }
  .ttc-tester-ui {
    font-family: $sans-serif;
    font-size: 1rem;
    opacity: .33;
    transition: all .1s ease-in-out;
  }
  li:hover .ttc-tester-ui {
    opacity: 1;
  }
  // .ttc-tester-size {
  //   margin: -1rem 0 1rem;
  // }
  li .tester-phrase {
    border-radius: 8px;
    transition: all .1s ease-in-out;
  }
  li:hover .tester-phrase {
    background: white(.166);
  }
}

//
//  Type tester
//

.font-tester {
  @include font-feature-settings(calt ccmp frac kern liga locl mark);

  background: white(0);
  border-radius: 4px;
  display: block;
  font-size: 2rem;
  height: auto;
  line-height: 1.1;
  margin: #{$baseline * 3}rem 0;
  max-width: $max-size;
  overflow: hidden;
  padding: 5px;
  position: relative;
  white-space: nowrap;
  width: 100%;

  mask-image: linear-gradient(to right, black 92%, transparent);

  & + & {
    margin-top: -#{$baseline * 3}rem;
  }

  .font-tester-sample:focus {
    outline: none;
  }

  .editable,
  [contentEditable] {
    border-radius: 8px;
    transition: all .1s ease-in-out;
  }
  .editable:focus,
  .editable:hover,
  [contentEditable]:hover {
    background: white(.166);
    outline: none;
  }

  $scale: 12;
  @while $scale > 0 {
    .scale__#{$scale} { font-size: .75rem * $scale; }
    @include breakpoint($medium, 'min') {
      .scale__#{$scale} { font-size: 2.5vw * $scale; }
    }

    @include breakpoint($large, 'min') {
      .scale__#{$scale} { font-size: 1.75rem * $scale; }
    }

    $scale: $scale - 1;
  }

  .font-tester-caption {
    font-family: $sans-serif;
    padding-top: #{$baseline/2}rem;
    font-size: 1rem;
    opacity: .66;
  }
}

// Tester with font and size swapping, and feature toggles
.js-ttc-tester {
  padding: 2rem 1.5rem;
  position: relative;

  background: white(.25);
  border-radius: 1rem;

  @include breakpoint($medium, 'min') {
    &::before {
      @include prefix(transition, opacity .25s ease);
      @include prefix(transform, translateX(-50%));       
      @include font-feature-settings(smcp c2sc kern);
      background: $white;
      border-radius: 4px;
      color: black(.33);
      content: '• • •';
      display: block;
      font-family: $sans-serif;
      height: 24px;
      letter-spacing: .02em;
      left: 50%;
      line-height: 24px;
      position: absolute;
      text-align: center;
      top: -12px;
      width: 48px;
    }
    &:hover::before {
      opacity: 0;
    }
  }
    
  &:hover {
    background: white(.33);
    outline: none;
  }

  .ttc-tester-samples p {
    margin-bottom: 0;
    max-width: 100%;

    &:focus {
      outline: none;
    }
  }

  .js-ttc-tester-controls {
    @include prefix(transition, all .25s ease);
    @include prefix(transform, translateX(-50%));
    opacity: 0;
    position: absolute;
    left: 50%;
    top: -1.25rem;
    z-index: 650;
  }

  @include breakpoint($medium, 'max') {
    .js-ttc-tester-controls {
      @include prefix(transform, translateX(0));
      left: 0;
      opacity: 1;
      width: 100%;

      div[class^='js-ttc-tester-'] ul {
        @include prefix(transform, translateX(0));
        left: 0;
      }
    }
  }

  &:hover .js-ttc-tester-controls {
    opacity: 1;
    top: -1rem;
  }

  .js-ttc-tester-font-select,
  .js-ttc-tester-size-select,
  .js-ttc-tester-feature-select {
    @include box-sizing(border-box);
    background: #fefefe;
    border-radius: 4px;
    float: left;
    width: auto;
    position: relative;
    margin-right: 2px;
    -webkit-user-select: none;
    user-select: none;

    &:hover .js-ttc-tester-label {
      background: $background;
      cursor: pointer;
    }

    .js-ttc-tester-label {
      @include font-feature-settings(smcp c2sc kern);
      border-radius: 4px;
      font-family: $sans-serif;
      letter-spacing: .02em;
      padding: .15rem .6rem .2rem;
      text-decoration: none;
    }

    ul {
      @include box-sizing(border-box);
      @include prefix(box-shadow, 0 4px 8px black(.1), 0 0 1px 1px black(.1));
      @include prefix(transform, translateX(-50%));
      background: #fafafa;
      border-radius: 4px;
      display: none;
      font-family: $sans-serif;
      left: 50%;
      margin-top: -2px;
      position: absolute;
      width: 220px;
      z-index: 750;
    }

    &:hover > ul {
      display: block;
    }

    li {
      cursor: pointer;
      list-style: none;
    }

    a {
      cursor: pointer;
      display: block;
      padding: .2rem .4rem;
      text-decoration: none;
    }

    label {
      display: block;
      margin: 0;
      padding: .2rem .4rem;

      &:hover {
        background: rgba($turquoise, .33);
      }
    }

    input[type="checkbox"] {
      margin: 0 .4rem 0 0;
      vertical-align: sub;

      &::after {
        content: '';
      }

      &:checked::after {
        color: transparent;
        content: '✔';
      }
    }
  }

  .ttc-tester--paragraph {
    font-size: 16px;
    line-height: 1.4;

    margin: 0 auto;
    max-width: 32rem;
  }

  .ttc-tester--headline {
    display: none;
    font-size: 24px;
    line-height: 1.25;
    text-align: center;
    margin: 0 auto;
    max-width: 62rem;
    
    @include breakpoint($medium, 'min') {
      font-size: 3vw;
    }
    @include breakpoint($max-size, 'min') {
      font-size: 50px;
    }
  }

  .ttc-tester--big {
    display: none;
    font-size: 48px;
    line-height: 1.1;
    text-align: center;
    margin: 0 auto;
    max-width: 62rem;
    
    @include breakpoint($medium, 'min') {
      font-size: 6vw;
    }
    @include breakpoint($max-size, 'min') {
      font-size: 96px;
    }
  }
}

// Feature classes
// This is so that the case feature, for example, also turns on all-caps
.fea--small-caps {
  @include font-feature-settings(kern calt liga frac smcp);
  &.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac smcp onum); }
  &.fea--lining-numbers { @include font-feature-settings(kern calt liga frac smcp lnum); }
  &.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp tnum); }
  &.fea--old-style-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp onum tnum); }
  &.fea--lining-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp lnum tnum); }
}

.fea--all-small-caps {
  @include font-feature-settings(kern calt liga frac smcp c2sc);
  &.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac smcp c2sc onum); }
  &.fea--lining-numbers { @include font-feature-settings(kern calt liga frac smcp c2sc lnum); }
  &.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp c2sc tnum); }
  &.fea--old-style-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp c2sc onum tnum); }
  &.fea--lining-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac smcp c2sc lnum tnum); }
}

.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac tnum); }
.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac onum); }
.fea--old-style-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac onum tnum); }
.fea--lining-numbers { @include font-feature-settings(kern calt liga frac lnum); }
.fea--lining-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac lnum tnum); }
.fea--lining-numbers.fea--tabular-numbers.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac lnum tnum); }
.fea--superscript { @include font-feature-settings(kern calt liga frac sups); }
.fea--subscript { @include font-feature-settings(kern calt liga frac subs); }

.fea--ordn { @include font-feature-settings(kern calt liga ordn); }

.fea--all-caps {
  @include font-feature-settings(kern calt liga frac case cpsp);
  text-transform: uppercase;
  &.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac case cpsp tnum); }
  &.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac case cpsp onum); }
  &.fea--lining-numbers { @include font-feature-settings(kern calt liga frac case cpsp lnum); }
  &.fea--old-style-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac case cpsp onum tnum); }
  &.fea--lining-numbers.fea--tabular-numbers { @include font-feature-settings(kern calt liga frac case cpsp lnum tnum); }
  .fea--lining-numbers.fea--tabular-numbers.fea--old-style-numbers { @include font-feature-settings(kern calt liga frac case cpsp lnum tnum); }
}



//
//  Type page specifics
//

.container {
  @extend %row;
}

.family-body {
  padding-top: 2rem;

  h3, p {
    padding: 0 #{$column-gutter / 2};
  }
}

.technical-information {
  // background: $secondary-white;
  background: rgba(255,255,255,.875);
  color: $text-black;
  font-family: $sans-serif;
  margin: 4rem 0 0;
  padding: 2rem 1rem 4rem;

  @include prefix(
    box-shadow, 0 0 20px black(.1),
                0 0 0 1px black(.1)
  );

  @include breakpoint($small, 'min') {
    padding: 1.5rem 1rem 3rem;
  }
}

.font-meta {

  a {
    color: $text-black;
    border-color: rgba($text-black, .5);

    &:hover {
      border-color: $text-black;
    }
  }

  .font-meta-features {
    @include column(3);
    margin-right: 0;
  }

  .font-meta-summary {
    @include column(2);

    dt {
      font-weight: bold;
    }
    dd {
      margin-bottom: .5rem;
      margin-left: 0;
    }
  }

  .font-meta-opentype {
    clear: left;
    @include column(4);

    li {
      list-style: none;
      display: grid;
      grid-template-columns: 1.25fr 1.75fr;
      grid-template-areas:
            "label nofea nofea"
            "..... featr featr";
      grid-column-gap: $column-gutter * 2;
      margin-bottom: 1.5rem;
    }

    @include breakpoint($medium, 'max') {
      li {
        display: block;
        margin-bottom: 1.5rem;
        overflow: hidden;
      }
    }

    span.otf_name {
      font-family: $sans-serif;
      font-weight: normal;
      opacity: .66;
    }

    i {
      font-style: normal;
      background: rgba($yellow, 0);
      grid-area: label;
    }

    &:hover i {
      background: rgba($yellow, .75);
    }

    span.otf__before,
    span[class*='fea-'] {
      display: block;
      font-size: 1.5rem;
      overflow: hidden;
      white-space: nowrap;

      @include breakpoint($small, 'max') {
        // font-size: 1.5rem;
      }
    }

    .otf__before {
      grid-area: nofea;
      font-feature-settings: 'kern' 1, 'calt' 0, 'liga' 0, 'tnum' 0, 'lnum' 0, 'onum' 0, 'pnum' 0, 'smcp' 0, 'c2sc' 0, 'locl' 0, 'frac' 0, 'case' 0, 'dlig' 0, 'ordn' 0, 'ss01' 0, 'ss02' 0, 'ss03' 0;
      color: rgba($text-black, .66);
    }
    $feature-list: kern calt liga locl frac tnum lnum onum pnum smcp c2sc case dlig ordn ss01 ss02 ss03;
    @each $fea in $feature-list {
      .otf__fea-#{$fea} {
        grid-area: featr;
        margin-bottom: .5rem;
        @if $fea == 'c2sc' {
          font-feature-settings: 'kern' 1, 'smcp'1 ,'#{$fea}' 1;
        } @else {
          font-feature-settings: 'kern' 1, '#{$fea}' 1;
        }
      }
    }
    .otf__onum-off {
      font-feature-settings: 'kern' 1, 'onum' 0, 'lnum' 1;
    }
    .otf__lnum-off {
      font-feature-settings: 'kern' 1, 'lnum' 0, 'onum' 1;
    }
  }

  .font-meta-specimen {
   @include column(2);

    img {
      display: block;
      width: 100%;
      max-width: 180px;
    }

    a,
    a:hover {
      @include prefix(transition, box-shadow .2s ease-in-out);

      border: 0;
      box-shadow: 0 0 8px rgba($black, .1), 0 0 2px rgba($black, .15);
      display: block;
      padding: 0;
      position: relative;
      width: 100%;
      max-width: 180px;
    }

    a:hover {
      box-shadow: 1px 2px 12px rgba($black, .15), 0 0 2px rgba($black, .15);
    }

    a::after {
      @include prefix(transition, opacity .33s ease-in-out);

      background: $white;
      border-radius: 50%;
      box-shadow: 0 0 8px rgba($text-black, .5), 0 0 2px rgba($black, .15);
      color: $text-black;
      content: '☞';
      font-family: $sans-serif;
      font-size: 2rem;
      height: 4rem;
      left: 50%;
      line-height: 3.5rem;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
    }

    a:hover::after {
      opacity: 1;
    }
  }
  .font-meta-charset {
    @include column(3);
    max-height: 90vh;
    overflow: auto;
    padding-bottom: 2rem;
    position: sticky;
    top: 5vh;

    div.charset--list {

      .charset--glyphlist {
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
        padding: 10px;

        li {
          list-style: none;
          background: #fff;
          box-shadow: 0 0 2px 1px rgba($black, .1);
          display: inline-block;
          width: 2.8rem;
          height: 2.8rem;
          font-size: 1.4rem;
          line-height: 2.8rem;
          text-align: center;
          margin: 0;
          user-select: none;

          &:hover {
            transform: scale(1.66);
            border-radius: 4px;
          }
        }
      }
    }
  }
  .font-meta-fiu {
    @include column(2);

    ul {
      list-style: none;

      li::before {
        content: "→ ";
      }
    }
  }
}


//
//  Shopping cart
//

// Cart itself
.shopping-cart {

  @include breakpoint($medium, 'max') {
    display: block;
    background: $white;
    color: $text-black;
    padding: 0 8px .25rem;
    position: relative;

    .js-close-button {
      display: none;
    }

    table.cart-table thead {
      display: none;
    }

    table.cart-table tfoot {
      display: block;
      th {
        width: 45% !important;
        display: inline-block;
      }
      td {
        width: 25% !important;
        display: inline-block;
        text-align: right;
      }
    }

    table.cart-table th, table.cart-table tr {
      display: block;
      width: 100% !important;
      padding: 4px 0;
      font-size: 14px;
    }
    table.cart-table .cart-table-sku {
      display: block;
      width: 100% !important;
    }
    table.cart-table .cart-table-license {
      display: block;
      width: 55% !important;
      display: inline-block;
    }
    table.cart-table .line-total {
      display: block;
      width: 25% !important;
      display: inline-block;
    }
    table.cart-table tr:not(:last-of-type) {
      border-bottom: 1px solid #ddd;
    }
  }

  @include breakpoint($medium, 'min') {
    @include prefix(
      box-shadow, 0 4px 16px black(.2),
                  0 6px 32px black(.15),
                  0 0 0 1px black(.1)
    );
    @include box-sizing(border-box);

    background: $white;
    color: $text-black;
    font-size: 1rem;
    height: auto;
    left: 50%;
    max-width: ($large * .8);
    min-width: 260px;
    transform: translateX(-50%);
    width: 100%;
    z-index: 900;
    border-radius: 4px;
    max-height: calc(100vh - 8.5rem);
    min-height: 6rem;
    overflow: auto;
    padding: .5rem 1.25rem 2.5rem;
    position: fixed;
    top: 5.5rem;
    width: 92%;
  }

  .js-close-button {
    color: darken($button, 15%);
    height: 64px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: 72px;
    top: 0;
    width: 64px;

    &::after {
      content: '×';
      display: block;
      font-size: 32px;
      left: 0;
      line-height: 64px;
      opacity: 1;
      position: absolute;
      text-align: center;
      text-indent: 0;
      top: 0;
      width: 64px;
    }

    &:focus::after,
    &:hover::after {
      color: darken($button, 25%);
    }
  }

  &.empty-cart {
    max-width: 34em;
    cursor: pointer;

    @include breakpoint($medium, 'max') {
      h2 {
        font-family: $sans-serif;
        font-weight: bold;
        font-size: 1rem;
        margin: 1rem 0 .25rem;
        padding-top: 1rem;
      }
      p {
        font-size: 1rem;
        margin: 0 0 1.5rem;
      }
    }

    @include breakpoint($medium, 'min') {
      h2 {
        font-family: dover-serif-display-regular;
        font-size: 3rem;
        margin-bottom: 1.5rem;
        text-align: center;
      }
      p {
        font-family: dover-sans-display-regular;
        font-size: 1.5rem;
        margin: 0 auto;
        max-width: 24rem;
        text-align: center;
      }  
    }
  }

  h3 {
    font-size: inherit;
    font-family: $sans-serif;
    font-weight: bold;
    margin: 0;
    padding-top: .5rem;
  }

  @include breakpoint($medium, 'min') {
    h3 {
      margin: 1rem 0 .5rem;
      padding-top: 0;
      text-align: center;
      font-family: dover-sans-display-regular, $sans-serif;
      font-weight: normal;
      font-size: 2rem;
    }
  }
  .cart-table {
    width: 100%;

    td,
    th {
      padding: .5rem .75rem;
    }

    tr th {
      font-weight: normal;
      text-align: left;
    }

    .remove-item {
      color: lighten($red, 25%);
      display: inline-block;
      font-size: 1.5rem;
      line-height: 1;
      margin-right: .25rem;

      &:focus,
      &:hover {
        color: $red;
      }
    }

    .line-total {
      @include font-feature-settings(calt kern lnum tnum);

      text-align: right;
      width: 4rem;
    }

    .cart-table-sku {
      width: 50%;
    }

    thead, tfoot {
      th {
        @include font-feature-settings(c2sc smcp calt kern lnum);
        color: rgba($text-black, .5);
      }
      th.thead-style {
        padding-left: 3.2rem;
      }
      th.thead-price {
        text-align: right;
      }
    }
    tfoot {
      th {
        padding-left: 3.2rem;
      }
      th, td {
        background: rgba($text-black, .025);
      }
    }
  }

  .form-select-item {
    select {
      @include prefix(
        box-shadow,
        none
      );
      background: rgba($turquoise, .05);
      background-image: none;
      border-style: solid;
      border-width: 1px;
      border-color: rgba($text-black, .5);

      &:hover {
        background: rgba($turquoise, .1);
      }
    }

    &::after {
      opacity: .666;
    }

    &:focus::after,
    &:hover::after {
      opacity: 1;
    }
  }

  .primary {
    height: 2.5rem;
    line-height: 2.3rem;
    margin: 2rem 0 1rem;
    padding: 0 1.5rem;
    position: relative;

    @include breakpoint($medium, 'max') {
      float: none;
      margin-bottom: 1rem;
    }
  }

  .js-cart-copy_purchase_link {
    float: left;
    margin: 2rem 1rem 1rem 0;
    padding: 0 1.5rem;
    line-height: 2.3rem;
    background: #ccc;
    color: #222;
    border: 1px solid rgba(0,0,0,.05);
    position: relative;
    z-index: 950;
    user-select: none;

    @include breakpoint($medium, 'max') {
      font-variant-caps: normal;
      margin-bottom: -1rem;
    }

    &::after {
      display: block;
      position: absolute;
      top: -.4rem;
      opacity: 0;
      height: 1.6rem;
      background: #444;
      transition: all .2s ease;
      content: 'Link copied to pasteboard';
      border-radius: 4px;
      z-index: 940;
      width: 112%;
      padding: .125rem .5rem;
      color: $white;
      left: 0;
      text-align: center;
      line-height: 1.6rem;
      font-size: .875rem;
      pointer-events: none;
    }
    &.alert-in::after {
      top: -.4rem;
      opacity: 0;
      z-index: 890;
    }
    &.alert-out::after {
      opacity: 1;
      top: -2.2rem;
    }
  }

}


// Add fonts to cart
.order-typeface-container {
  @include prefix(
    box-shadow, 0 0 20px black(.1),
                0 0 0 1px black(.1)
  );
  background: $white;
  color: $text-black;
  font-family: $sans-serif;
  margin: 0 0 1rem;
  padding: 2rem 1rem 1.5rem;

  @include breakpoint($small, 'min') {
    padding: 2rem 1rem 1.5rem;
  }

  h3 {
    margin: 0 0 .25rem;
  }

  h4 {
    margin: 0;
  }

  fieldset {
    margin: 0 auto;
  }

  fieldset:last-of-type {
    margin: 0 auto #{$baseline}rem;
  }

  a {
    color: $text-black;
    border-color: rgba($text-black, .5);

    &:hover {
      border-color: $text-black;
    }
  }

  .container {
    // max-width: $max-size;
  //  padding: 0 1rem;
  }

  .cart-style-list {
    padding: 1rem 0;
  }

  .license-info {
    @include column(7);
    margin-bottom: 1rem;
    opacity: .75;
    // padding: 0;

    p {
      margin-bottom: #{$baseline/2}rem;
    }

    @include breakpoint($medium, 'min') {
      p {
        max-width: 50%;
      }
    }
  }

  .license-selection {
    @include column(7);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  .license-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    @include breakpoint($medium, 'min') {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: max-content;
    }
    grid-gap: .5rem;
    max-width: 100%;
    margin: 1rem 0 0;

    h4 {
      font-size: 1.125rem;
      font-weight: bold;
      @include font-feature-settings(kern calt);
      border-bottom: 1px solid rgba($text-black, .5);
      padding: .5rem .5rem .25rem 2rem;
      margin: 0 0 .25em;
      position: relative;

      &::before {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 1rem;
        content: ' ';
        border: 2px solid $text-black;
        position: absolute;
        left: .25rem;
        top: .5rem;
      }
    }
    p {
      padding: 0 .5rem;

      &:last-of-type {
        padding-bottom: .5rem;
      }
    }
    input[type='radio'] {
      visibility: hidden;
      position: absolute;
    }
  }

  .form-select-license {
    @include prefix(transition, all .125s ease-out);
    text-decoration: none;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    border: 1px solid rgba($text-black, .5);

    &:hover {
      background: rgba($yellow, .075);
      transform: scale(1.025);
    }
  }
  input[type='radio']:checked + label.form-select-license {
    box-shadow: 0 4px 16px -4px rgba($text-black, .25), inset 0 -1px 0 rgba(0,0,0,.05);
//    When SASS updates, this is the format for lightening
//    color: color.scale($turquoise, $lightness: 15%, $saturation: -5%);
    transform: scale(1.025);

    h4 {
      background-color: $text-black;
      color: $text-white;
      &:before {
        background-image: url('/assets/images/checkmark.svg');
        background-color: $text-white;
        background-repeat: no-repeat;
        background-position: 40% 50%;
        background-size: .875rem auto;
        }
    }
  }

  .cart-font-styles {
    @include column(7);
    margin: 0 0 #{$baseline}rem;
    width: 100%;

    li {
      margin: 0;
      overflow: hidden;

      &.style-row {
        border: 1px solid rgba($text-black, .5);
        border-width: 1px 1px 0 1px;

        &:last-of-type {
          border-width: 1px;
        }

      }
      div.font-selection {
        border: 1px solid rgba($text-black, .5);
      }
    }

    div {
      @extend %cf;

      margin: 0;
    }

    label {
      display: block;
      height: 3rem;
      margin: 0;
      padding: 0;
    }

    li:last-of-type div {
      border-bottom: 0;
    }

    .selection-note {
      background: transparent;
      line-height: 2rem;

      p {
        margin: 0;
        opacity: .75;
        padding: .25rem .5rem 0;
      }
    }

    .font-selection,
    .style-selection {
      &.disabled {
        opacity: .5;

        input, label {
          cursor: default !important;
        }
      }
      &:not(.disabled):hover {
        background: rgba($teal-dark,.075);
      }
    }

    .font-selection {
      label {
        font-family: dover-sans-display-regular;
        font-size: 1.5rem;
        height: 3rem;
        line-height: 3rem;

        @include breakpoint($medium, 'max') {
          font-family: $sans-serif;
          font-size: 1rem;
        }
      }
    }

    .style-selection {
      padding: 0;
    }

    [type='checkbox'] {
      border: 2px solid $text-black;
      cursor: pointer;
      height: 2rem;
      margin: .5rem .5rem 0 .5rem;
      width: 2rem;

      // &:focus,
      &:checked:focus {
        background-color: $text-black;
        background-color: var(--page-text-color);
      }

      &::after {
        background-position: 2rem 35%;
        background-size: 1.2rem auto;
        background-image: url('/assets/images/checkmark_negative.svg');
        color: white(0);
        height: 1.8rem;
        line-height: 2rem;
        width: 1.8rem;
        transition: none;
      }

      &:checked {
        background-color: $text-black;
      }

      &:checked::after {
        background-color: $text-black;
        background-position: 45% 45%;
      }
    }
  }

  .label--entire-family {
    display: inline-block;
    width: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sample {
    @include font-feature-settings(calt frac kern liga);

    display: inline-block;
    font-size: 1.25rem;
    line-height: 3rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 69%;

    @include breakpoint($small, 'min') {
      font-size: 1.5rem;
    }

    @include breakpoint($medium, 'min') {
      display: inline-block;
      font-size: 1.5rem;
      width: 80%;
    }

    @include breakpoint($large, 'min') {
      font-size: 2rem;
    }
  }

  .style-price {
    color: $text-black;
    display: block;
    float: right;
    line-height: 2.875rem;
    padding: 0 .4rem 0 0;
    text-align: right;
    width: calc(33% - 4.5rem);

    @include breakpoint($medium, 'min') {
      width: calc(20% - 5.4rem);
    }
  }

  .cart-total {
    @extend %row;

    @include breakpoint($medium, 'min') {
      p {
        padding: 0 .5rem;
      }

      p:first-of-type {
        float: left;
        width: 75%;
      }

      p:last-of-type {
        float: right;
        text-align: right;
        width: 25%;

        button {
          height: 3rem;
        }
      }
    }

    .cart-total-count {
//      background: lighten($turquoise, 30%);
      border-radius: 4px;
      line-height: 3rem;
      padding: 0 1rem;
    }
  }
}


//
//  Auxiliary pages
//

.front-subscribe,
.thank-you-mail {
  margin: 0 auto;
  max-width: 32rem;

  form {
    text-align: center;
  }

  input {
    text-align: center;
  }
}

.page-ext {
  h2 {
    max-width: 100%;
    text-align: center;
  }

  hr {
    margin-left: auto;
    margin-right: auto;
  }
}

.body--thank-you {
  h2.thanks--title {
    font-family: dover-serif-display-italic;
    font-style: italic;
    font-size: 5rem;
    line-height: #{$baseline * 3}rem;
  }
}

.thank-you-mail {
  // h2 {
    // text-align: left;
  // }

  p {
    margin-bottom: 2rem;
  }
}

.license-detail {
  @include column(6);

  footer p {
    max-width: 40rem;
  }
}

.license {
  @include prefix(columns, 24rem 2);
  @include prefix(column-gap, #{$column-gutter});

  counter-reset: license-main-points;
  max-width: 100%;
  padding: 0;

  li {
    break-inside: avoid;
    -webkit-column-break-inside: avoid;
    counter-increment: license-main-points;

    list-style: none;
    margin-bottom: #{$baseline / 2}rem;
    margin-left: 0;
    max-width: 32rem;
    padding-left: 2rem;
    page-break-inside: avoid;
    position: relative;

    &::before {
      @include font-feature-settings(kern tnum lnum);

      content: counter(license-main-points);
      display: block;
      font-family: $sans-serif;
      left: 0;
      position: absolute;
      text-align: right;
      width: 1rem;
    }

    ol {
      padding-left: 0;
    }

    li {
      counter-increment: license-sub-points;
      padding-left: 2rem;

      &::before {
        content: counter(license-sub-points, lower-alpha);
        left: -.5rem;
      }
    }
  }

  strong {
    @extend %em-note;
  }
}

.please-note {
  @extend %em-note;
}

.faq-sample {
  margin: 1.5rem 0 3rem;

  // @media (min-width: $medium) {
  //   dl dt {
  //     float: left;
  //     width: 20%;
  //   }

  //   dl dd {
  //     float: left;
  //     width: 75%;
  //   }

  //   dl dd + dd {
  //     margin-left: 20%;
  //   }
  // }

  dl dt {
    font-family: $sans-serif;
    padding-left: 1rem;
  }

  dl dd {
    color: rgba($text-white, .5);
    font-family: dover-serif-display-regular;
    font-size: 2rem;
    margin: 0 0 .5rem;
    padding-left: 2rem;

    img {
      max-width: 48rem;
    }

    span {
      color: $text-white;
    }
  }
}

.faq-languages {
  ul {
    font-family: $sans-serif;
    max-width: 48rem;
    padding-left: 2rem;

    li {
      display: inline-block;

      &::after {
        content: ', ';
        opacity: .75;
      }

      &:nth-last-of-type(2)::after {
        content: ' and ';
      }

      &:last-of-type::after {
        content: '.';
      }
    }
  }
}

.page-faq {
  h3:not(:first-of-type) {
    margin-top: 6rem;
  }
  h4 {
    font-family: dover-sans-display-italic;
    font-style: italic;
    font-weight: 400;
    font-size: 1.5rem;
  }
}

// .page-contact {
// }

.trial-hero {
  clear: left;
  @include column(7);

  .mc-field-group {
    margin-bottom: .75rem;
  }

  input[type="text"], input[type="email"] {
    box-sizing: border-box;
    max-width: 280px;
    width: 100%;
  }

  button {
    box-sizing: border-box;
    float: none;
    max-width: 280px;
    padding-left: .33rem;
    padding-right: .33rem;
    text-align: center;
    width: 100%;
  }

  #mc_embed_signup_scroll {
    @extend %cf;
  }
}

.trial-lede {
  @include column(4);
}

.trial-aside {
  @include column(3);
}

.trial-license {
  @include column(6);
}

.error-page {
  h2 {
    @include gradient($button, $white);

    // background-attachment: fixed;
    -webkit-background-clip: text;
    background-clip: text;
    background-position: 0 0;
    background-size: cover;
    color: white(.5);
    font-family: monumental-grotesk;
    font-size: 30vw;
    line-height: 1;
    margin-bottom: 10vh;
    margin-top: 0;
    -webkit-text-fill-color: transparent;

    @include breakpoint('1600px', 'min') {
      font-size: 30rem;
      margin-top: -4rem;
    }
  }
}

aside {
  @include column(2);

  color: white(.75);
  float: right;
  font-family: $sans-serif;
  margin: 0 0 2rem;

  @include breakpoint($medium, 'max') {
    padding-left: 1rem;
    border-left: 2px solid $yellow;

    margin-top: 1rem;
    h3 { margin-top: 0 !important; }
  }
  
  @include breakpoint($medium, 'min') {
    h3 { margin-top: 1rem !important; }
    border-top: 2px solid $yellow;
    position: relative;
  }

  & + & {
    margin-top: -1rem;
    clear: right;
  }
}


// For whenever I might need a rainbow that animates
// If you’re able to imagine such a thing

@keyframes rainbow {
  0% {
    background-position: 0 0;
   }

  100% {
    background-position: 2400px 0;
  }
}

.egg {
  // scss-lint:disable all

  position: relative;

  .window {
    background: #eee;
    border: 3px double black;
    box-sizing: border-box;
    display: block;
    font-family: 'Peaches Pixel 5';
    font-size: 20px;
    -webkit-font-smoothing: none;
    height: #{290 + 24 + 24 + 6}px;
    left: 22%;
    position: absolute;
    top: 20%;
    width: #{178 + 6}px;
    z-index: 955;

    &.wider {
      height: 26vw;
      left: 45%;
      top: 16%;
      width: 40vw;
      z-index: 960;
    }

    &.hatwobble {
      height: 110px;
      left: 30%;
      overflow: hidden;
      top: 36%;
      width: 40vw;
      z-index: 965;

      .bigtype {
        background: black;
        color: white;
        display: block;
        font-size: 60px;
        text-align: center;
      }
    }

    .titlebar {
      background: darkblue;
      display: block;
      height: 24px;

      span {
        background: #eee;
        border-bottom: 1px solid black;
        box-shadow: inset -1px -1px 0 #bbb, inset 1px 1px 0 #fff;
        color: black;
        display: block;
        height: 23px;
        line-height: 24px;
        text-align: center;
        width: 24px;

        &.close {
          border-right: 1px solid black;
          float: left;
        }

        &.minimise {
          border-left: 1px solid black;
          font-size: 10px;
          float: right;
          width: auto;
          padding: 0 8px;
        }
        &.text {
          font-family: 'Peaches Pixel 5';
        }

        .video video {
          height: auto;
          width: 100%;
        }
      }
    }

    .infobar {
      background: white;
      border-bottom: 1px solid black;
      color: black;
      display: block;
      height: 24px;
      line-height: 24px;
      padding: 0 4px;
    }
  }
}
