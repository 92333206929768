$serif: 'Dover Serif Text', 'Adobe Caslon', Caslon, serif;
$sans-serif: 'Dover Sans Text', Gill Sans, sans-serif;

//
//  Font options
//
@mixin getfont($slug, $italic: 'false', $weight: 'normal', $altname: '') {
  // The slug is the CSS name and also, for ease of use, the file name
  // The mixin expects to find .woff2 and .woff files.
  // It's possible to label italics, but ideally it can do whole families.
  // That way, I can use the fonts like on OS-level. Consider TODO

  $src: $slug;
  @if $altname != '' {
    $src: $altname;
  }

  .font__#{$slug} {
    font-family: '#{$slug}';
    @if $italic == 'italic' {font-style: italic;
    } @else {font-style: normal;}
  }

  @font-face {
    font-family: '#{$slug}';
    src: url("/assets/fonts/#{$src}.woff2") format('woff2');
    font-weight: #{$weight};
    @if $italic == 'italic' {
      font-style: italic;
    } @else {
      font-style: normal;
    }
  }

}

//
//  Core TTC fonts:
//  - Dover Serif Text
//  - Dover Sans Text
//  - Dover Serif Display
//

@font-face {
  font-family: 'Dover Serif Text';
  src: url('/assets/fonts/dover-serif-text-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dover Serif Text';
  src: url('/assets/fonts/dover-serif-text-italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Dover Serif Text';
  src: url('/assets/fonts/dover-serif-text-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dover Sans Text';
  src: url('/assets/fonts/dover-sans-text-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dover Sans Text';
  src: url('/assets/fonts/dover-sans-text-italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Dover Sans Text';
  src: url('/assets/fonts/dover-sans-text-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


//
//  TTC release web fonts
//

@include getfont('dover-serif-display-regular');
@include getfont('dover-serif-display-italic', 'italic');
@include getfont('dover-sans-display-regular');
@include getfont('dover-sans-display-italic', 'italic');
@include getfont('monumental-grotesk');

// Sketching
@include getfont('monumental-neon');

@include getfont('tiny-grotesk-light', 'false', '300');
@include getfont('tiny-grotesk-regular', 'false', '400');
@include getfont('tiny-grotesk-medium', 'false', '500');
@include getfont('tiny-grotesk-bold', 'false', '700');
@include getfont('tiny-grotesk-narrow-light', 'false', '300');
@include getfont('tiny-grotesk-narrow-regular', 'false', '400');
@include getfont('tiny-grotesk-narrow-medium', 'false', '500');
@include getfont('tiny-grotesk-narrow-bold', 'false', '700');
@include getfont('tiny-grotesk-wide-light', 'false', '300');
@include getfont('tiny-grotesk-wide-regular', 'false', '400');
@include getfont('tiny-grotesk-wide-medium', 'false', '500');
@include getfont('tiny-grotesk-wide-bold', 'false', '700');

@include getfont('dover-serif-text-regular');
@include getfont('dover-serif-text-italic', 'italic');
@include getfont('dover-serif-text-bold', 'false', '700');
@include getfont('dover-sans-text-regular');
@include getfont('dover-sans-text-italic', 'italic');
@include getfont('dover-sans-text-bold', 'false', '700');
